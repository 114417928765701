import useAspidaSWR from '@aspida/swr';
import { api } from '@atnd/api-client';
export const useCalendarState = ({ ccid, clinicAlias, date, isOnline, mode = 'day' }) => {
    const isSelectedCC = !!ccid && !!clinicAlias;
    const { data: slotList, error, isLoading, } = useAspidaSWR(api.list_slots
        ._url_alias(clinicAlias ?? '')
        ._ccid(ccid ?? '')
        ._date(date ?? '')
        ._mode(mode ?? ''), '$get', {
        refreshInterval: isOnline ? 1000 * 60 * 1 : undefined,
        key: isSelectedCC ? [clinicAlias, ccid, date, mode] : undefined,
    });
    return { slotList, isLoading, error };
};
