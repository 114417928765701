import { useCallback, useMemo, useState } from 'react';
export const usePagination = ({ initialPage = 0, initialPerPage, total }) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [perPage, setPerPage] = useState(initialPerPage);
    const lastPage = Math.ceil(total / perPage);
    const isFirstPage = useMemo(() => currentPage === 0, [currentPage]);
    const isLastPage = useMemo(() => currentPage === lastPage, [currentPage, lastPage]);
    const prev = useCallback(() => {
        if (isFirstPage)
            return;
        setCurrentPage(currentPage - 1);
    }, [currentPage, isFirstPage]);
    const next = useCallback(() => {
        if (isLastPage)
            return;
        setCurrentPage(currentPage + 1);
    }, [currentPage, isLastPage]);
    const toFirst = () => {
        setCurrentPage(0);
    };
    const toLast = () => {
        setCurrentPage(lastPage);
    };
    const toPage = (page) => {
        if (page > 0 && page <= lastPage) {
            setCurrentPage(page);
        }
    };
    return {
        currentPage,
        perPage,
        lastPage,
        isFirstPage,
        isLastPage,
        prev,
        next,
        toFirst,
        toLast,
        toPage,
        setPerPage,
    };
};
