export const ja = {
    ///////////// Common /////////////": "共通項目",
    DESCRIPTION: '詳細',
    FILTER: 'フィルター:',
    FILTER_PLACEHOLDER: '入力して絞り込み...',
    ITEMS: '項目名',
    ITEMS_NOT_FOUND: '表示する項目がありません。',
    INVALID: '値が不正です',
    PREVIOUS: '戻る',
    NEXT: '次へ',
    ITEMS_PER_PAGE: '表示件数',
    TOTAL: '合計',
    LOGOUT: 'ログアウト',
    EMAIL: 'メールアドレス',
    PASSWORD: 'パスワード',
    REQUIRED: '入力が必須な項目です。',
    SUBMIT: '送信',
    UPLOAD: 'アップロード',
    DOWNLOAD: 'ダウンロード',
    CANCEL: 'キャンセル',
    CONFIRM: '確定',
    SAVE: '保存',
    CHANGE: '変更',
    EDIT: '編集',
    UPDATE: '更新',
    DELETE: '削除',
    ADDRESS: {
        POSTAL_CODE: '郵便番号',
        PREFECTURE: '都道府県',
        CITY: '市区町村',
        STREET: '番地以下',
    },
    NAV: {
        RESERVATION_HISTORY: '予約履歴',
        NOTIFICATIONS: 'お知らせ',
        CONTACT: 'お問い合わせ',
    },
    ///////////// Menu /////////////": "メニュー項目",
    MENU: {
        ABOUT: '概要',
        ADMIN_DASHBOARD: '管理者ダッシュボード',
        FAQ: 'よくある質問',
        ///////////// App Menu /////////////": "Appメニュー項目",
        DASHBOARD: 'ダッシュボード',
        HOSPITAL_SETTINGS: '医院設定',
        PAYMENTS: 'お支払い',
        USER_SETTINGS: '個人設定',
    },
    ///////////// Views: Welcome /////////////": "ビュー: Welcome",
    WELCOME: {
        TITLE: 'Atndへようこそ！',
        DESCRIPTION: 'まず初めに、医院との紐づけを行います。',
        NEW_HOSPITAL: {
            TITLE: '新規医院を登録',
            DESCRIPTION: '医院として初めてAtndを利用される方はこちら。',
            REGISTER: '新規医院を登録',
        },
        INVITATION: {
            TITLE: '登録済みの医院にアカウントを紐づけ',
            DESCRIPTION: '登録済みの医院から招待を受けている方はこちら。',
            CONFIRM_INVITATION: '招待を確認',
        },
    },
    ///////////// Views: Dashboard /////////////": "ビュー: Dashboard",
    DASHBOARD: {
        TITLE: 'ダッシュボード',
        DESCRIPTION: 'ここに説明を入力',
    },
    ///////////// Views: Login /////////////": "ビュー: Login",
    LOGIN: {
        TITLE: 'ログイン',
        DESCRIPTION: 'アカウントにログインする',
        LOGIN: 'ログイン',
        FORGET_PASSWORD: 'パスワードをお忘れの方',
        ERROR: {
            LOGIN_FAILED: 'ログインに失敗しました',
        },
        SIGNUP: {
            TITLE: '新規登録',
            DESCRIPTION: '無料ですぐに始められます',
            REGISTER: '今すぐ登録',
        },
    },
    ///////////// Views: Signup /////////////": "ビュー: Signup",
    SIGNUP: {
        TITLE: '新規登録',
        DESCRIPTION: 'アカウントを作成する',
        ERROR: {
            EMAIL_ALREADY_IN_USE: 'このメールアドレスは既に登録されています。',
            INTERNAL_ERROR: 'メールアドレスまたはパスワードの値が不正です。',
            UNKNOWN_ERROR: '予期せぬエラーが発生しました。',
        },
        REGISTER: '登録する',
        ACCOUNT_INFO: 'アカウント情報',
        BASIC_INFO: '基本情報',
        CONTACT_INFO: '連絡先',
    },
    TOP: {
        MY_RESERVATIONS: '予約一覧',
        SEARCH: '病院の検索・予約',
    },
    ///////////// Views: HospitalSettings /////////////": "ビュー: HospitalSettings",
    HOSPITAL_SETTINGS: {
        TITLE: '医院設定',
        DESCRIPTION: '医院設定の説明をここに記入する',
        PAYMENTS: {
            TITLE: 'お支払い',
        },
    },
    ///////////// Views: UserSettings /////////////": "ビュー: UserSettings",
    USER_SETTINGS: {
        TITLE: '個人設定',
        DESCRIPTION: '個人設定の説明をここに記入する',
        CONTACTS: 'ご連絡先',
        DESCRIPTIONS: {
            USER_ID: 'この項目は編集できませんと言った内容を記載する。',
            CONTACTS: '必要な場合にご連絡することがあると言った内容を記載する。',
            TITLE: '職種に関する情報をここに記載する。',
        },
    },
    ///////////// Models: User /////////////": "モデル: User",
    USER: {
        USER_ID: 'ユーザーID',
        LAST_NAME: '姓',
        FIRST_NAME: '名',
        LAST_NAME_KANA: '姓(ふりがな)',
        FIRST_NAME_KANA: '名(ふりがな)',
        EMAIL: 'メールアドレス',
        CHANGE_EMAIL: '新しいメールアドレスに確認メールを送信',
        CHANGED_EMAIL: ' 新しいメールアドレス',
        tel: '電話番号',
        PASSWORD: 'パスワード',
        PASSWORD_CONFIRMATION: 'パスワード(再入力)',
        PASSWORD_PLACEHOLDER: 'メールアドレスの変更はパスワードが必要です',
        BIRTHDAY: '生年月日',
        GENDER: '性別',
        HAS_ALLERGY: 'アレルギーの有無',
        ALLERGY_DETAILS: 'アレルギーの詳細',
    },
    ///////////// Models: Hospital /////////////": "モデル: Hospital",
    HOSPITAL: {
        NAME: '医院名',
        NAME_KANA: '医院名(かな)',
    },
    RESERVE: {
        CALENDAR: {
            MONDAY: '月',
            TUESDAY: '火',
            WEDNESDAY: '水',
            THURSDAY: '木',
            FRIDAY: '金',
            SATURDAY: '土',
            SUNDAY: '日',
            HOLIDAY: '祝',
            TO_PREVIOUS_WEEK: '前の週',
            TO_NEXT_WEEK: '次の週',
        },
        CHIEF_COMPLAINT: '主な症状',
        DATETIME: '日時',
        INQUIRY: '問診',
        MAKE_RESERVATION: '予約する',
    },
    VALIDATION: {
        REQUIRED: '必須項目です。',
        INVALID_TYPE: '入力値の形式が正しくありません。',
        EMAIL: 'メールアドレスの形式が正しくありません。',
        PASSWORD: 'パスワードは英字・数字両方を含め8文字以上で入力してください。',
        tel: '電話番号の形式が正しくありません。',
        NUMBER: {
            EXACT: '{{minimum}}の数値である必要があります。',
            INCLUSIVE: '{{minimum}}以上の数値を入力してください。',
            NOT_INCLUSIVE: '{{minimum}}より大きい数値を入力してください。',
        },
        KANA: 'カタカナで入力してください。',
        HIRAGANA: 'ひらがなで入力してください。',
    },
};
