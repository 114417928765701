import { useCallback } from 'react';
export const useInsertTemplate = ({ setText, text }) => {
    const insertToText = useCallback((templateIndex) => {
        const template = templates[templateIndex];
        const newText = text + template?.value;
        setText(newText);
    }, [text, setText]);
    return {
        insertToText,
        templates,
    };
};
const templates = [
    {
        label: 'Do処方',
        value: `Do処方 `,
    },
    {
        label: '処方調整',
        value: `処方調整 `,
    },
    {
        label: '採血予定',
        value: `採血予定 `,
    },
    {
        label: '検査結果返却',
        value: `検査結果返却 `,
    },
    {
        label: '休職中',
        value: `休職中 `,
    },
    {
        label: '診断書',
        value: `診断書 `,
    },
    {
        label: '傷病手当意見書',
        value: `傷病手当意見書 `,
    },
    {
        label: '紹介状',
        value: `紹介状 `,
    },
];
