import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useState } from 'react';
import { v4 } from 'uuid';
import { useSnackbar } from '../features/notification/useSnackbar';
import { useInput } from './useInput';
export const useTemplates = () => {
    const { openSnackbar } = useSnackbar();
    const [templates, setTemplates] = useAtom(templatesAtom);
    const [isEdit, setIsEdit] = useState(false);
    const [currentTemplateId, setCurrentTemplateId] = useState(undefined);
    const currentTemplate = templates.find((template) => template.id === currentTemplateId);
    const [newTemplate, setNewTemplate] = useState({
        name: currentTemplate?.name || '',
        text: currentTemplate?.text || '',
    });
    const { handleInputChange } = useInput({ setState: setNewTemplate });
    const toggleEdit = () => {
        setIsEdit((prev) => !prev);
        setNewTemplate({
            name: currentTemplate?.name ?? '',
            text: currentTemplate?.text ?? '',
        });
    };
    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentTemplate?.text ?? '');
        openSnackbar('クリップボードにコピーしました', 'info');
    };
    const selectTemplate = (id) => {
        const template = templates.find((template) => template.id === id);
        if (!template)
            return;
        setCurrentTemplateId(template.id);
        setNewTemplate(template);
        setTemplates((prev) => {
            const newTemplates = prev.filter((template) => template.id !== id);
            return [template, ...newTemplates];
        });
    };
    const addTemplate = () => {
        setTemplates((prev) => [{ ...(currentTemplate && currentTemplate), ...newTemplate, id: v4() }, ...prev]);
        openSnackbar('テンプレートを作成しました', 'success');
        setIsEdit(false);
    };
    const updateTemplate = () => {
        setTemplates((prev) => prev.map((prevTemplate) => {
            if (!currentTemplateId)
                return prevTemplate;
            if (currentTemplate && prevTemplate.id === currentTemplateId) {
                return { ...currentTemplate, ...newTemplate };
            }
            return prevTemplate;
        }));
        openSnackbar('テンプレートを更新しました', 'success');
        setIsEdit(false);
    };
    const removeTemplate = () => {
        if (!currentTemplateId)
            return;
        setTemplates((prev) => {
            const removed = prev.filter((template) => template.id !== currentTemplateId);
            return removed;
        });
        setCurrentTemplateId(undefined);
        openSnackbar('テンプレートを削除しました', 'success');
    };
    return {
        templates,
        currentTemplate,
        newTemplate,
        handleInputChange,
        selectTemplate,
        addTemplate,
        updateTemplate,
        removeTemplate,
        copyToClipboard,
        isEdit,
        toggleEdit,
    };
};
const initialTemplate = {
    id: v4(),
    name: '',
    text: '',
};
const templatesAtom = atomWithStorage('templates', [initialTemplate]);
