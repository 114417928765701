export const en = {
    ///////////// Common /////////////": "共通項目",
    DESCRIPTION: 'Description',
    FILTER: 'Filter:',
    FILTER_PLACEHOLDER: 'Enter and filter...',
    ITEMS: '項目名',
    ITEMS_NOT_FOUND: 'No items to display.',
    INVALID: 'Invalid value',
    PREVIOUS: 'Prev',
    NEXT: 'Next',
    ITEMS_PER_PAGE: 'Items per page',
    TOTAL: 'Total',
    LOGOUT: 'Logout',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    REQUIRED: 'This field is required.',
    SUBMIT: 'Submit',
    UPLOAD: 'Upload',
    DOWNLOAD: 'Download',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    CHANGE: 'Change',
    EDIT: 'Edit',
    UPDATE: 'Update',
    DELETE: 'Delete',
    ADDRESS: {
        POSTAL_CODE: 'Postal Code',
        PREFECTURE: 'Prefecture',
        CITY: 'City',
        STREET: 'Street',
    },
    NAV: {
        RESERVATION_HISTORY: 'Reservation History',
        NOTIFICATIONS: 'Notifications',
        CONTACT: 'Contact',
    },
    ///////////// Menu /////////////": "メニュー項目",
    MENU: {
        ABOUT: 'About',
        ADMIN_DASHBOARD: 'Admin Dashboard',
        FAQ: 'FAQ',
        ///////////// App Menu /////////////": "Appメニュー項目",
        DASHBOARD: 'ダッシュボード',
        HOSPITAL_SETTINGS: 'Hospital Settings',
        PAYMENTS: 'Payments',
        USER_SETTINGS: 'User Settings',
    },
    ///////////// Views: Welcome /////////////": "ビュー: Welcome",
    WELCOME: {
        TITLE: 'Atndへようこそ！',
        DESCRIPTION: 'まず初めに、医院との紐づけを行います。',
        NEW_HOSPITAL: {
            TITLE: '新規医院を登録',
            DESCRIPTION: '医院として初めてAtndを利用される方はこちら。',
            REGISTER: '新規医院を登録',
        },
        INVITATION: {
            TITLE: '登録済みの医院にアカウントを紐づけ',
            DESCRIPTION: '登録済みの医院から招待を受けている方はこちら。',
            CONFIRM_INVITATION: '招待を確認',
        },
    },
    ///////////// Views: Dashboard /////////////": "ビュー: Dashboard",
    DASHBOARD: {
        TITLE: 'Dashboard',
        DESCRIPTION: 'Enter description here',
    },
    ///////////// Views: Login /////////////": "ビュー: Login",
    LOGIN: {
        TITLE: 'Login',
        DESCRIPTION: 'Log in to your account',
        LOGIN: 'Login',
        FORGET_PASSWORD: 'Forgot your password?',
        ERROR: {
            LOGIN_FAILED: 'Login has failed.',
        },
        SIGNUP: {
            TITLE: 'New Registration',
            DESCRIPTION: 'Get started right away for free!',
            REGISTER: 'Register Now',
        },
    },
    ///////////// Views: Signup /////////////": "ビュー: Signup",
    SIGNUP: {
        TITLE: '新規登録',
        DESCRIPTION: 'アカウントを作成する',
        ERROR: {
            EMAIL_ALREADY_IN_USE: 'このメールアドレスは既に登録されています。',
            INTERNAL_ERROR: 'メールアドレスまたはパスワードの値が不正です。',
            UNKNOWN_ERROR: '予期せぬエラーが発生しました。',
        },
        REGISTER: '登録する',
        ACCOUNT_INFO: 'Account Info',
        BASIC_INFO: 'Basic Info(Unalterable after registration)',
        CONTACT_INFO: 'Contact Info',
    },
    TOP: {
        MY_RESERVATIONS: '予約一覧',
        SEARCH: 'Search for Clinic and Reserve',
    },
    ///////////// Views: HospitalSettings /////////////": "ビュー: HospitalSettings",
    HOSPITAL_SETTINGS: {
        TITLE: '医院設定',
        DESCRIPTION: '医院設定の説明をここに記入する',
        PAYMENTS: {
            TITLE: 'お支払い',
        },
    },
    ///////////// Views: UserSettings /////////////": "ビュー: UserSettings",
    USER_SETTINGS: {
        TITLE: '個人設定',
        DESCRIPTION: '個人設定の説明をここに記入する',
        CONTACTS: 'ご連絡先',
        DESCRIPTIONS: {
            USER_ID: 'この項目は編集できませんと言った内容を記載する。',
            CONTACTS: '必要な場合にご連絡することがあると言った内容を記載する。',
            TITLE: '職種に関する情報をここに記載する。',
        },
    },
    ///////////// Models: User /////////////": "モデル: User",
    USER: {
        USER_ID: 'UserID',
        LAST_NAME: 'Last Name',
        FIRST_NAME: 'First Name',
        LAST_NAME_KANA: 'Last Name (Kana)',
        FIRST_NAME_KANA: 'First Name (Kana)',
        EMAIL: 'Email',
        CHANGE_EMAIL: 'Change Email',
        CHANGED_EMAIL: 'Changed Email',
        tel: 'Phone Number',
        PASSWORD: 'Password',
        PASSWORD_CONFIRMATION: 'Password(re-entering)',
        PASSWORD_PLACEHOLDER: 'You need a password to change your email address.',
        BIRTHDAY: 'Birthday',
        GENDER: 'Gender',
        HAS_ALLERGY: 'Presence of Allergies',
        ALLERGY_DETAILS: 'Allergy Details',
    },
    ///////////// Models: Hospital /////////////": "モデル: Hospital",
    HOSPITAL: {
        NAME: '医院名',
        NAME_KANA: '医院名(かな)',
    },
    RESERVE: {
        CALENDAR: {
            MONDAY: 'M',
            TUESDAY: 'T',
            WEDNESDAY: 'W',
            THURSDAY: 'T',
            FRIDAY: 'F',
            SATURDAY: 'S',
            SUNDAY: 'S',
            HOLIDAY: 'H',
            TO_PREVIOUS_WEEK: 'Prev Week',
            TO_NEXT_WEEK: 'Next Week',
        },
        CHIEF_COMPLAINT: 'C.C',
        DATETIME: 'Date & Time',
        INQUIRY: 'Inquiry',
        MAKE_RESERVATION: 'Make a reservation',
    },
    VALIDATION: {
        REQUIRED: 'Required',
        INVALID_TYPE: 'Input value format is incorrect.',
        EMAIL: 'Invalid email address',
        PASSWORD: 'Password must be at least 6 characters long and contain at least one letter and one number.',
        tel: 'Invalid phone number',
        NUMBER: {
            EXACT: 'Number must be exactly {{minimum}}',
            INCLUSIVE: 'Number must be greater than or equal to {{minimum}}',
            NOT_INCLUSIVE: 'Number must be greater than {{minimum}}',
        },
        KANA: 'please enter in katakana',
        HIRAGANA: 'please enter in hiragana',
    },
};
