import { useCallback } from 'react';
export const useInput = (props) => {
    const { setState } = props;
    const setValue = useCallback((name, value) => {
        const names = name.split('.');
        if (!names.length)
            return;
        /**
         * @example
         * 引数の例
         * const name = 'user.address.prefecture'
         * const names = ['user', 'address', 'prefecture']
         * const value = '東京都'
         *
         * 更新対象のオブジェクトにアクセスするために、再起処理を行う
         * ① updateValue(prevState, ['user'], "東京都")
         * ② updateValue(prevState['user'], ['address', "prefecture"], "東京都")
         * ③ updateValue(prevState['user']['address'], ['prefecture'], "東京都")
         */
        const updateValue = (acc, names, value) => {
            const [firstName, ...restNames] = names;
            // 末端の要素でない場合は再起処理を行う
            if (restNames.length) {
                const currentValue = acc[firstName];
                if (Array.isArray(currentValue)) {
                    const index = Number.parseInt(restNames[0] ?? '');
                    const updatedValue = updateValue(currentValue[index], restNames.slice(1), value);
                    const newArray = currentValue.splice(index, 1, updatedValue);
                    return { ...acc, [firstName]: newArray };
                }
                return {
                    ...acc,
                    [firstName]: updateValue(acc[firstName], restNames, value),
                };
            }
            return { ...acc, [firstName]: value };
        };
        return setState((prev) => {
            return updateValue(prev, names, value);
        });
    }, [setState]);
    /**
     *
     * @summary
     * DOM 要素の `name` 属性に基づいた状態を更新します。
     * @param e - 入力要素の変更イベント．
     * @param options - オプションオブジェクト．
     *  - `type`: 入力要素の種類を示す文字列。`checkbox`の場合は`checked`プロパティが状態値として設定され、
     *            `array`の場合は`value`プロパティが文字列として取得され、コンマ区切りで配列化されます。
     *
     * @description
     * `options.type` が `checkbox` の場合，入力要素(e.target)の `checked` プロパティが状態値として設定されます．
     * `array` の場合は、入力要素の `value` プロパティが文字列として取得され、コンマ区切りで配列化されます。
     * それ以外の場合は、入力要素の `value` プロパティが設定されます。
     *
     * @example
     * const [state, setState] = useState({ user: { name: 'Mike', isAgreed: false } })
     *
     * <input type="text" name="user.name" onChange={handleInputChange} />
     * 'John'を入力 // { user: { name: 'John', isAgreed: false } }
     *
     * <input type="checkbox" name="user.isAgreed" onChange={(e) => handleInputChange(e, { type: 'checkbox' })} />
     * チェックボックスをオン // { user: { name: 'Mike', isAgreed: true } }
     */
    const handleInputChange = useCallback((e, options) => {
        const { checked, name, type, value } = e.target;
        if (options?.type === 'checkbox') {
            setValue(name, checked);
        }
        else if (options?.type === 'array') {
            const arrayValue = value
                ? value
                    .trim()
                    .split(',')
                    .map((item) => {
                    const numValue = Number(item);
                    return Number.isNaN(numValue) ? item : numValue;
                })
                : [];
            setValue(name, arrayValue);
        }
        else if (options?.type === 'radio') {
            handleRadioChange(e, value);
        }
        else if (type === 'number') {
            if (value === '')
                return setValue(name, null);
            setValue(name, Number(value));
        }
        else {
            setValue(name, value);
        }
    }, [setValue]);
    const handleRadioChange = useCallback((e, value) => {
        const { name } = e.target;
        const numValue = Number(value);
        const literalOrBool = value === 'true' ? true : value === 'false' ? false : Number.isNaN(numValue) ? value : numValue;
        setValue(name, literalOrBool);
    }, [setValue]);
    const handleDateChange = useCallback((date, name) => {
        setValue(name, date);
    }, [setValue]);
    return { handleInputChange, handleDateChange };
};
