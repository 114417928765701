import { useTheme } from '@mui/material';
export const useThemePallette = () => {
    const theme = useTheme();
    return {
        ...theme.palette,
        container: {
            background: theme.palette.background.paper,
            border: theme.palette.divider,
            iconBackground: theme.palette.grey[100],
            iconColor: theme.palette.primary.main,
        },
    };
};
