import { useParams } from 'next/navigation';
import { useCallback } from 'react';
import { en, ja } from '../locales';
// TODO: locale引数を削除
export const useLocale = (locale) => {
    const lang = useParams().lang;
    const t = lang === 'en' ? en : (ja ?? locale);
    const text = useCallback((key) => {
        // キーをドットで分割
        // 例: 'ADDRESS.POSTAL_CODE' => ['ADDRESS', 'POSTAL_CODE']
        const keys = key.split('.');
        const lastKey = keys[keys.length - 1];
        const isRoot = keys.length === 1;
        if (isRoot) {
            return t[lastKey];
        }
        const value = keys.reduce((acc, key) => {
            return acc[key];
        }, t);
        if (typeof value === 'string') {
            return value;
        }
        return '';
    }, [t]);
    return { locale: lang, t, text };
};
