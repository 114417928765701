import { useState } from 'react';
export const useStep = (initialStep = 1, maxStep = 2) => {
    const [step, setStep] = useState(initialStep);
    const next = () => setStep((prev) => Math.min(prev + 1, maxStep));
    const prev = () => setStep((prev) => Math.max(prev - 1, 1));
    const reset = () => setStep(initialStep);
    const isLastStep = step === maxStep;
    const isFirstStep = step === 1;
    return { step, next, prev, reset, isLastStep, isFirstStep };
};
