import { useCallback, useId, useState } from 'react';
/**
 * @description
 * `useDisclosure` は、一般的な`open`、`close`、`toggle`シナリオを処理するために使用されるカスタムフックです。
 * これは、 `Modal`, `Popover`, `Drawer` などのフィードバックコンポーネントを制御するために使用することができます。
 */
export const useDisclosure = (props = {}) => {
    const { id: idProp, isOpen: isOpenProp, onClose: onCloseProp, onOpen: onOpenProp } = props;
    const handleOpen = onOpenProp;
    const handleClose = onCloseProp;
    const [isOpenState, setIsOpen] = useState(props.defaultIsOpen || false);
    const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;
    const isControlled = isOpenProp !== undefined;
    const uid = useId();
    const id = idProp ?? `disclosure-${uid}`;
    const onClose = useCallback(() => {
        if (!isControlled) {
            setIsOpen(false);
        }
        handleClose?.();
    }, [isControlled, handleClose]);
    const onOpen = useCallback(() => {
        if (!isControlled) {
            setIsOpen(true);
        }
        handleOpen?.();
    }, [isControlled, handleOpen]);
    const onToggle = useCallback(() => {
        if (isOpen) {
            onClose();
        }
        else {
            onOpen();
        }
    }, [isOpen, onOpen, onClose]);
    /**
     * @description
     * `getButtonProps` は、フィードバックコンポーネントのボタン(トリガー)に渡す必要があるすべてのプロパティを返します。
     * これは、`aria-expanded`、`aria-controls`、`onClick`などのプロパティを含みます。
     */
    const getButtonProps = (props = {}) => {
        return {
            ...props,
            'aria-expanded': isOpen,
            'aria-controls': id,
            onClick(event) {
                props.onClick?.(event);
                onToggle();
            },
        };
    };
    const getDisclosureProps = (props = {}) => {
        return {
            ...props,
            hidden: !isOpen,
            id,
            onClose,
            open: isOpen,
        };
    };
    return {
        isOpen,
        onOpen,
        onClose,
        onToggle,
        isControlled,
        getButtonProps,
        getDisclosureProps,
    };
};
