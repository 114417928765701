import { useState } from 'react';
import { appDayjs, formatDate } from '@atnd/dayjs';
export const useCalendar = ({ dayValue = 7, initialDate, initialMode = 'day', shallowPush }) => {
    const [date, setDate] = useState(initialDate ?? today);
    const [mode, setMode] = useState(initialMode);
    const toPrev = () => {
        const prevDate = appDayjs(date).subtract(dayValue, 'day').format('YYYY-MM-DD');
        setDate(prevDate);
        shallowPush?.(prevDate);
    };
    const toNext = () => {
        const nextDate = appDayjs(date).add(dayValue, 'day').format('YYYY-MM-DD');
        setDate(nextDate);
        shallowPush?.(nextDate);
    };
    const toCurrent = () => {
        setDate(today);
        shallowPush?.(today);
    };
    const to = (date) => {
        setDate(date);
        shallowPush?.(date);
    };
    return {
        date,
        to,
        toPrev,
        toNext,
        toCurrent,
        mode,
        setMode,
    };
};
const today = formatDate(new Date());
