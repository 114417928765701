import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
export const usePopper = ({ disableHandleClickAway = false, } = {}) => {
    const [anchorEl, setAnchorEl] = useState(undefined);
    const popperRef = useRef(null);
    const onOpen = (event) => {
        setAnchorEl(anchorEl ? undefined : event.currentTarget);
    };
    const onClose = (_) => {
        setAnchorEl(undefined);
    };
    const handleClickAway = useCallback((target, _) => {
        if (popperRef.current?.contains(target)) {
            return;
        }
        setAnchorEl(undefined);
    }, []);
    useEffect(() => {
        if (disableHandleClickAway)
            return;
        document.addEventListener('click', (e) => handleClickAway(e.target, e));
        return () => {
            document.removeEventListener('click', (e) => handleClickAway(e.target, e));
        };
    }, [disableHandleClickAway, handleClickAway]);
    const open = Boolean(anchorEl);
    const popperId = open ? 'simple-popper' : undefined;
    return {
        popperRef,
        anchorEl,
        onOpen,
        onClose,
        open,
        popperId,
        handleClickAway,
    };
};
